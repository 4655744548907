export const API_BASE_URL =
  process.env.REACT_APP_MYDATA_API_BASE_URL || window.location.origin;
export const API_SA_BASE_URL =
  process.env.REACT_APP_SA_BASE_URL || "https://account.samsung.com";
export const API_CHINA_SA_BASE_URL =
  process.env.REACT_APP_CHINA_SA_BASE_URL || "https://account.samsung.cn";
export const MYDATA_HISTORY = `${API_BASE_URL}/my-data/history`;
export const API_PUBLIC_AUTH_TOKEN =
  "Bearer N2V2Mnh4d3oxODpVMEZOVTFWT1IxQlNTVlpCUTFrPQ==";

export const API_ENDPOINTS = {
  LOGIN: "/accounts/v1/SDAP/signInGate",
  LOGOUT: "/accounts/v1/SDAP/signOutGate",
  AUTH_SIGNIN_URL: "/user/v2/tokens/access",
  AUTH_SIGNOUT_URL: "/user/v2/tokens/expire",
  PROFILE: "/user/v2/profile",
  GEOIP: "/mydata/v1/geoip",
  SA_PROFILE: "/membership/contents/profile",
  SERVICES: "/mydata/v1/service",
  SUMMARY: "/mydata/v1/summary",
  DETAILS: "/mydata/v1/history/detail",
  SEND_FILE_PASSWORD: "/mydata/v1/sendcode",
  CANCEL_REQUEST: "/mydata/v1/cancel",
  HISTORY: "/mydata/v1/history",
  CONFIRM_PASS_URL: "/accounts/v1/SDAP/confirmPasswordGate",
  MYDATA_DOWNLOAD: "/mydata/v1/download",
  MYDATA_DELETE: "/mydata/v1/delete",
  MYDATA_UNSUBSCRIBE: "/mydata/v1/unsubscribe",
  SURVEY: "mydata/v1/survey",
  VERIFY_OTP: "/mydata/v1/guest-login/verify-otp",
  EMAIL_LOGIN_SEND_OTP: "/mydata/v1/guest-login",
};
