import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { ApplicationState } from "../../store";
import { LoginOptionState } from "../../store/reducers/login-option-reducer";

interface Props {
  children?: React.ReactNode;
}
const ScrollToTop: React.FC<Props> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { isLoginModalOpen } = useSelector<ApplicationState, LoginOptionState>(
    (state) => state.loginOption
  );

  useEffect(() => {
    if (history.action !== "REPLACE" && !isLoginModalOpen) {
      document.querySelector("main")!.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
  }, [location, history]);

  return <>{props.children}</>;
};

export default ScrollToTop;
