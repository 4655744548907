export const CLIENT_ID = "7ev2xxwz18"; // same with appId
export const ACR_VALUES =
  process.env.REACT_APP_SA_BASE_URL || "urn:samsungaccount:acr:aal1";
export const ACR_KEY = process.env.REACT_APP_SA_ACR_KEY || "rotate";

export const MOBILE_MAX_WIDTH = 767;
export const TABLET_PORTRAIT_MAX_WIDTH = 1023;
export const SITE_ACCESS_KEY_SECRETS =
  process.env.REACT_APP_SITE_ACCESS_KEY || ""; //site access key stored in github secrets
export const SAMSUNG_ACCOUNT_PORTAL = "https://account.samsung.com";
