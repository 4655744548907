import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { ROUTES } from "../../configs/routes";
import { Svg, SVG_ICONS } from "../../components/__common/Svg/Svg";
import { useWindowSize } from "../../hooks/window-hooks";
import "./Footer.scss";
import { FooterMobile } from "./FooterMobile";
import { Container } from "../Grid/Grid";
import { LanguageModal } from "../LanguageModal/LanguageModal";
import { useLanguage } from "../../hooks/language-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { UserState } from "../../store/reducers/user-reducer";
import { useTranslation } from "react-i18next";
import { ManageCookieModal } from "../CookieModal/ManageCookie";
import { useCookie } from "../../hooks/cookie-hooks";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import {
  EVENT_CATEGORY_FOOTER,
  EVENT_FOOTER_ACTION_COOKIE_POLICY,
  EVENT_FOOTER_ACTION_PRIVACY_POLICY,
  EVENT_ACTION_MANAGE_COOKIES,
} from "../../constants/analytics";
import classNames from "classnames";
import { useLayout } from "../../hooks/layout-hooks";
import FocusTrapping from "../__hoc/FocusTrapping";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import { ROUTES_CONSIGNMENT_PAGE } from "../../configs/layout";

export const Footer: React.FC = () => {
  const { isMobile } = useWindowSize();
  const { setManageCookieModalOpen } = useCookie();
  const { isLanguageModalShow, setIsLanguageModalShow } = useLanguage();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const { country, language, isManageCookieOpen } = layoutState;

  useEffect(() => {
    if (isLanguageModalShow) {
      document.getElementById("language-modal-tts")?.focus();
    }
  }, [isLanguageModalShow]);

  return (
    <>
      {isManageCookieOpen && (
        <FocusTrapping
          isActive={isManageCookieOpen}
          elements="svg, button, div.title2 , div.modal-body, div.manage-cookie"
        >
          <ManageCookieModal
            isOpen={isManageCookieOpen}
            onHide={() => setManageCookieModalOpen(false)}
          />
        </FocusTrapping>
      )}
      <FocusTrapping
        isActive={isLanguageModalShow}
        elements="div.modal-body, div.title2, svg, button, input"
      >
        <LanguageModal
          isSiteLanguage
          show={isLanguageModalShow}
          onHide={() => setIsLanguageModalShow(false)}
        />
      </FocusTrapping>
      {!isMobile ? (
        <FooterDesktop
          onClickLanguage={() => setIsLanguageModalShow(true)}
          onClickManageCookie={() => setManageCookieModalOpen(true)}
          country={country}
          language={language}
        />
      ) : (
        <FooterMobile
          onClickLanguage={() => setIsLanguageModalShow(true)}
          onClickManageCookie={() => setManageCookieModalOpen(true)}
          country={country}
          language={language}
        />
      )}
    </>
  );
};

interface DesktopProps {
  onClickLanguage?: () => void;
  onClickManageCookie?: () => void;
  country?: string;
  language?: string;
}

export const FooterDesktop: React.FC<DesktopProps> = (props) => {
  const { t } = useTranslation();
  const { setTheme } = useLayout();
  const { pathname } = useLocation();

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { userRegion } = userState;
  const handleLanguageClick = () => {
    if (props.onClickLanguage) {
      props.onClickLanguage();
    }
  };

  const handleManageCookieClick = () => {
    if (props.onClickManageCookie) {
      triggerGAEvent(EVENT_CATEGORY_FOOTER, EVENT_ACTION_MANAGE_COOKIES);
      props.onClickManageCookie();
    }
  };

  return (
    <>
      <div
        className={classNames("footer", {
          "snap-scroll": pathname === ROUTES.ROOT,
          "consignment-footer": ROUTES_CONSIGNMENT_PAGE.includes(pathname),
        })}
      >
        <Container className="footer__content">
          <div className="footer__content__left">
            <div>
              {!routeShouldRenderComponent(pathname, ROUTES_CONSIGNMENT_PAGE) &&
              <div className="footer__content__left__links">
              <Link
                className="footer__link sharp-sans"
                to={ROUTES.PRIVACY_POLICY}
                onClick={() =>
                  triggerGAEvent(
                    EVENT_CATEGORY_FOOTER,
                    EVENT_FOOTER_ACTION_PRIVACY_POLICY
                  )
                }
              >
                {t("PRIVACY_HEADER_MENU_PRIVACY_POLICY")}
              </Link>
              {userRegion.isFromEurope && (
                <>
                  <span className="divider"></span>
                  <Link
                    className="footer__link sharp-sans"
                    to={ROUTES.COOKIE_POLICY}
                    onClick={() =>
                      triggerGAEvent(
                        EVENT_CATEGORY_FOOTER,
                        EVENT_FOOTER_ACTION_COOKIE_POLICY
                      )
                    }
                  >
                    {t("PRIVACY_FOOTER_MENU_COOKIES")}
                  </Link>

                    <span className="divider"></span>
                    {/* eslint-disable-next-line */}
                    <a
                      className="footer__link sharp-sans"
                      onClick={() => handleManageCookieClick()}
                      href="#"
                    >
                      {t("PRIVACY_FOOTER_MENU_MANAGE_COOKIES")}
                    </a>
                  </>
                )}

              <span className="divider"></span>
              <button
                className="footer__link sharp-sans"
                onClick={handleLanguageClick}
              >
                <span className="country">{props.country}</span>&nbsp;/&nbsp;
                <span>{props.language}</span>
                <Svg
                  className="arrow-right"
                  width={16}
                  height={16}
                  icon={SVG_ICONS.ARROW_RIGHT}
                />
              </button>
            </div>
              }


              
              <div className="footer__content__left__copyright sharp-sans">
                <span>
                  Copyright © Samsung Electronics Co, Ltd. All Rights Reserved
                </span>
                <div className="footer__content__left__links__themes">
                  <button className="icon-link" onClick={() => setTheme(true)}>
                    <Svg
                      width={16}
                      height={16}
                      icon={SVG_ICONS.FOOTER_THEME_HIGH_CONTRAST}
                    />
                  </button>
                  <button
                    className={classNames("icon-link", "icon-link-last")}
                    onClick={() => setTheme(false)}
                  >
                    <Svg
                      width={16}
                      height={16}
                      icon={SVG_ICONS.FOOTER_THEME_DEFAULT}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footer__content__right">
          <Svg
            className="arrow-right"
            width={212}
            height={27}
            icon={SVG_ICONS.LOGO}
          />
        </div> */}
        </Container>
      </div>
    </>
  );
};
