import * as jose from "jose";
import jwt from "jsonwebtoken";
import moment from "moment";
import { useEffect, useState } from "react";
import { COOKIE_ADSSO_TOKEN } from "../constants/storage";
import { Cookie } from "../services/storage";

export const useCheckSiteAccess = () => {
  // variables
  const SECRET_KEY = process.env.REACT_APP_JWT_SECRET_KEY!;
  const isProd =
    window.location.hostname === "privacy.samsung.com" ||
    window.location.hostname === "localhost" ||
    false;
  const [hasSiteAccess, setHasSiteAccess] = useState<boolean>(false);

  const ADSSO_TOKEN = Cookie.get(COOKIE_ADSSO_TOKEN) || false;

  // functions
  const declineAccess = (msg: string) => {
    if (msg !== "") {
      alert(msg);
    }
    setHasSiteAccess(false);
    ssoLoginRedirect();
  };

  const isJWTExpired = (exp: number) => {
    const expDate = new Date(exp * 1000);
    const formattedExpDate = moment(expDate).format("YYYY-MM-DD HH:mm:ss");
    const dateToday = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    // check if JWT is expired
    if (moment(formattedExpDate).isBefore(dateToday)) return true;
    return false;
  };

  const verifyTokenWithx509Cert = async (token: string, x509cert: string) => {
    try {
      const base64String = x509cert.match(/.{1,64}/g);
      // Convert the Base64 encoded certificate to PEM format
      const certPEM = `-----BEGIN CERTIFICATE-----\n${base64String?.join(
        "\n"
      )}\n-----END CERTIFICATE-----`;
      // Import the x509 certificate to get the public key
      const publicKey = await jose.importX509(certPEM, "RS256");

      // Verify the JWT
      const { payload, protectedHeader } = await jose.jwtVerify(
        token,
        publicKey,
        {
          algorithms: ["RS256"],
        }
      );

      return { payload, protectedHeader };
    } catch (error) {
      return "error";
    }
  };

  const jwtChecker = async () => {
    if (ADSSO_TOKEN) {
      // verify if the token stored in the cookie is signed using the SECRET KEY
      // to block other user from bypassing and generating their own JWT
      try {
        const { payload } = await jose.jwtVerify(
          ADSSO_TOKEN,
          new TextEncoder().encode(SECRET_KEY)
        );

        if (isJWTExpired(payload.exp!)) return declineAccess("");
        setHasSiteAccess(true);
      } catch (error) {
        declineAccess("Unauthorized access");
      }
    }
  };

  const getPathObj = () => {
    let path = window.location.href;
    // slice the path from #
    path = path.slice(path.indexOf("#") + 1);
    // Split the query string into key-value pairs separated by '&'
    const pairs = path.split("&");
    // Convert each pair into a key-value object using Object.fromEntries()
    const pathObj = Object.fromEntries(
      pairs.map((pair) => {
        const [key, value] = pair.split("=");
        return [decodeURIComponent(key), decodeURIComponent(value)];
      })
    );

    return pathObj;
  };

  const envChecker = () => {
    if (isProd) return setHasSiteAccess(true);
    if (!ADSSO_TOKEN) {
      alert(
        "redirecting to AD SSO Login to continue accessing non-prod ENV of Samsung Privacy"
      );
      // redirect to AD SSO login if the Cookies does not exist for non-dev env
      declineAccess("");
    }
  };

  const ssoLoginRedirect = () => {
    const nonce =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    window.location.href = `${process.env.REACT_APP_AD_SSO_BASE_URL}${process.env.REACT_APP_AD_SSO_AUTHORIZE_URL}?client_id=${process.env.REACT_APP_AD_SSO_CLIENT_ID}&scope=openid+profile&response_type=code+id_token&redirect_uri=${window.location.origin}&nonce=${nonce}`;
  };

  const generate30DaysJWT = (tokenData: jose.JWTPayload) => {
    if (isJWTExpired(tokenData.exp!)) return declineAccess("");

    // validate the token from ADSSO then generate a new JWT with a 30days expiration if valid
    const days_in_seconds = 60 * 60 * 24;
    const payload = {
      email: tokenData.upn,
      unique_name: tokenData.unique_name,
    };

    const token = jwt.sign(payload, SECRET_KEY, {
      algorithm: "HS256",
      expiresIn: 30 * days_in_seconds,
    });

    alert("access granted");
    setHasSiteAccess(true);
    Cookie.set(COOKIE_ADSSO_TOKEN, token);
    // manually remove the #code or ?client-request-id in the url from ADSSO after verification
    const href = window.location.href;
    const index = href.indexOf("#code");
    const clientIdIndex = href.indexOf("?client-request-id");
    const indexToRemove = clientIdIndex === -1 ? index : clientIdIndex;
    window.location.href = href.slice(0, indexToRemove);
  };

  const verifyIdToken = async (id_token: string) => {
    const result = await verifyTokenWithx509Cert(
      id_token,
      process.env.REACT_APP_AD_SSO_X509_CERT!
    );

    if (result && typeof result !== "string")
      return generate30DaysJWT(result.payload);

    declineAccess("Unauthorized access");
  };

  useEffect(() => {
    const pathObj = getPathObj();
    if (pathObj && pathObj.hasOwnProperty("id_token")) {
      verifyIdToken(pathObj.id_token);
    } else {
      jwtChecker();
      envChecker();
    }
  }, []);

  return !isProd ? hasSiteAccess : true;
};
