import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  EVENT_ACTION_RESEND_OTP,
  EVENT_ACTION_VERIFY_OTP,
  EVENT_CATEGORY_LOGIN_OPTIONS,
} from "../../constants/analytics";
import { useOTP } from "../../hooks/otp-hooks";
import triggerGAEvent from "../../utils/UtilsTriggerGAEvent";
import Modal from "../__common/Modal/Modal";
import { Typography } from "../__common/Typography/Typography";
import { Button } from "../__common/_controls";
import "./OTP.scss";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { LoadingButton } from "../__common/_controls/Button/Button";
import classNames from "classnames";

interface Props {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

export const OTPModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  // third party hooks
  const { t } = useTranslation();

  // custom hooks
  const { removeEmailLoginSession, removeGstToken, duration } =
    useLoginOptionHooks();
  const {
    otp,
    handlePaste,
    handleKeyDown,
    getStringVal,
    getTimerValue,
    secondsToMinutesAndSeconds,
    resendTimer,
    otpExpirationTimer,
    isOTPInvalid,
    isVerifying,
 
    handleOTPVerification,
    resendOTP,
    isOtpExpired,
    isHighContrast,
    maxAttemptReached,
    isDisabled,
  } = useOTP();

  const handleClose = () => {
    setIsOpen(false);
    removeEmailLoginSession();
    removeGstToken();
  };

  const renderOTPTimerMessage = () => {
    return (
      <>
        <Typography
          variant="body2"
          className={classNames("expired-otp-message", {
            hidden: !isOtpExpired || maxAttemptReached,
          })}
        >
          <p
            className="txt-danger"
            data-testid="expired-otp-message"
            id="expired-otp-message"
          >
            {t("PRIVACY_LOGIN_EMAIL_OTP_EXPIRED")}
          </p>
          <Trans
            i18nKey="PRIVACY_LOGIN_EMAIL_OTP_RESEND_LINK2"
            components={{
              a: (
                <button
                  id="otp-expired-resend-btn"
                  data-testid="otp-expired-resend-btn"
                  className={`btn-link ${isHighContrast ? "hc" : ""}`}
                  onClick={() => {
                    resendOTP();
                    triggerGAEvent(
                      EVENT_CATEGORY_LOGIN_OPTIONS,
                      EVENT_ACTION_RESEND_OTP
                    );
                  }}
                ></button>
              ),
            }}
          />
        </Typography>
        <Typography
          className={classNames({
            hidden:
              resendTimer > 0 ||
              isOTPInvalid ||
              isOtpExpired ||
              maxAttemptReached,
          })}
          variant="body2"
          component="p"
        >
          <button
            className="hidden"
            data-testid="resend-button"
            onClick={resendOTP}
          ></button>
          <Trans
            i18nKey="PRIVACY_LOGIN_EMAIL_OTP_RESEND_LINK"
            components={{
              a: (
                <button
                  id="resendTimerExpiredBtn"
                  className={`btn-link ${isHighContrast ? "hc" : ""}`}
                  data-testid="resendTimerExpiredBtn"
                  onClick={() => {
                    resendOTP();
                    triggerGAEvent(
                      EVENT_CATEGORY_LOGIN_OPTIONS,
                      EVENT_ACTION_RESEND_OTP
                    );
                  }}
                ></button>
              ),
            }}
          />
        </Typography>
        <Typography
          className={classNames({
            hidden: resendTimer <= 0 || isOTPInvalid || maxAttemptReached,
          })}
          variant="body2"
        >
          <p data-testid="wait-to-resend-txt">
            {t("PRIVACY_LOGIN_EMAIL_OTP_WAIT_TO_RESEND", {
              "%s": getTimerValue(),
            })}
          </p>
        </Typography>
        <Typography
          className={classNames({
            hidden: !isOTPInvalid || isOtpExpired,
          })}
          variant="body2"
          component="p"
        >
          <span
            className="txt-danger"
            data-testid="invalid-otp-error"
            id="invalid-otp-error"
          >
            {t("PRIVACY_LOGIN_EMAIL_OTP_ERROR")}
          </span>
          <Trans
            i18nKey="PRIVACY_LOGIN_EMAIL_OTP_RESEND_LINK2"
            components={{
              a: (
                <button
                  date-testid="resend-btn-expired"
                  className={`btn-link ${isHighContrast ? "hc" : ""}`}
                  onClick={resendOTP}
                ></button>
              ),
            }}
          />
        </Typography>
        <p
          className={classNames("max-attempt", {
            hidden: !maxAttemptReached,
          })}
          data-testid="invalid-otp-error2"
          id="invalid-otp-error"
        >
          {t("PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT")}
          <br />
          {t("PRIVACY_LOGIN_EMAIL_OTP_LOCKED_TEXT2", {
            "%s": `${duration.value}  ${t(duration.did)}`,
          })}
        </p>
      </>
    );
  };

  return (
    <>
      <Modal
        wrapperClassName="otp-modal"
        className="otp-modal-wrapper auto-height"
        show={isOpen}
        id="otp-modal"
      >
        <Modal.Header onHide={() => handleClose()}>
          <Modal.Title iconVisibility={false}>
            {t("PRIVACY_LOGIN_EMAIL_OTP_TITLE")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <Typography variant="body2" component="p" className="b2">
              {t("PRIVACY_LOGIN_EMAIL_OTP_TEXT")}
            </Typography>
            {!maxAttemptReached && (
              <Typography variant="body1" component="p">
                <strong>
                  {otpExpirationTimer > 0 ? (
                    <>
                      {secondsToMinutesAndSeconds(otpExpirationTimer).minutes}:
                      {secondsToMinutesAndSeconds(otpExpirationTimer).seconds}
                    </>
                  ) : (
                    "0:00"
                  )}
                </strong>
              </Typography>
            )}
          </div>
          <div className="otp-modal">
            <OTPInput
              isHighContrast={isHighContrast}
              otp={otp}
              handlePaste={handlePaste}
              handleKeyDown={handleKeyDown}
              otpLength={6}
              disabled={isOtpExpired || maxAttemptReached}
            />
          </div>
          <div className="text-center">{renderOTPTimerMessage()}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-container foccusable-div">
            <Button
              type="secondary"
              testId="cancel-request-button"
              title={t("PRIVACY_LOGIN_EMAIL_OTP_BTN_CANCEL")}
              onClick={() => handleClose()}
              className={`${isHighContrast ? "hc-btn" : null}`}
              id="cancel-request-button"
            ></Button>
          </div>
          <div className="button-container foccusable-div">
            <LoadingButton
              title={t("PRIVACY_LOGIN_EMAIL_OTP_BTN_VERIFY")}
              onClick={() => {
                handleOTPVerification(getStringVal());
                triggerGAEvent(
                  EVENT_CATEGORY_LOGIN_OPTIONS,
                  EVENT_ACTION_VERIFY_OTP
                );
                const otpModal = document.querySelector("div#otp-modal");
                if (otpModal) {
                  // set the active element as the otp Modal to avoid the focus going out after clicking verify bv
                  (otpModal as HTMLElement).setAttribute("tabindex", "0");
                  (otpModal as HTMLElement).focus();
                }
              }}
              disabled={
                getStringVal().length !== 6 ||
                isVerifying ||
                maxAttemptReached ||
                isOtpExpired || isDisabled
              }
              id="confirm-request-button"
              testId="confirm-request-button"
              loadingPosition="right"
              isLoading={isVerifying}
            ></LoadingButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface OTPInputProps {
  otp: string[];
  otpLength: number;
  handlePaste: (
    index: number,
    e: React.ClipboardEvent<HTMLInputElement>
  ) => void;
  handleKeyDown: (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  isHighContrast: boolean;
  disabled?: boolean;
}

export const OTPInput: React.FC<OTPInputProps> = (props) => {
  const { handlePaste, isHighContrast, disabled } = props;

  return (
    <div>
      <div className="otp-input-wrapper">
        {[...Array(props.otpLength)].map((_, index: number) => {
          return (
            <input
              className={`otp-input ${isHighContrast ? "hc" : null}`}
              key={index}
              type="number"
              data-testid={`otp-input-${index}`}
              id={`otp-input-${index}`}
              value={disabled ? "" : props.otp[index]}
              onPaste={(e) => handlePaste(index, e)}
              onKeyDown={(e) => props.handleKeyDown(index, e)}
              onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => {
                e.preventDefault();
                e.currentTarget.select();
              }}
              disabled={disabled}
            />
          );
        })}
      </div>
    </div>
  );
};
