import React, { useEffect } from "react";
import { Cookie } from "../../../services/storage";
import { COOKIE_ADSSO_TOKEN } from "../../../constants/storage";

interface ADSSOLogoutProps {}

const ADSSOLogout: React.FC<ADSSOLogoutProps> = () => {
  useEffect(() => {
    Cookie.remove(COOKIE_ADSSO_TOKEN);
    window.location.href = `${process.env.REACT_APP_AD_SSO_BASE_URL}/ls/?wa=wsignoutcleanup1.0`;
  }, []);
  return <div></div>;
};

export default ADSSOLogout;
