import "./CountrySpecific.scss";
import React from "react";
import { Container } from "../../../../../../components/Grid/Grid";
import { Typography } from "../../../../../../components/__common/Typography/Typography";
import PrivacyInfoCarousel from "../Carousels/PrivacyInfoCarousel/PrivacyInfoCarousel";
import { UtilsUser } from "../../../../../../utils/UtilsUser";
import CountryCarousel from "../Carousels/CountryCarousel/CountryCarousel";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { MyDataState } from "../../../../../../store/reducers/mydata-reducer";
import { useTranslation } from "react-i18next";


import RightsInfoCarousel  from "../Carousels/RightsInfoCarousel/RightsInfoCarousel";
import { useHistory } from "react-router-dom";
import { useHub } from "../../../../../../hooks/hub-hooks";
import { useLoginOptionHooks } from "../../../../../../hooks/login-option-hooks";
import { ROUTES } from "../../../../../../configs/routes";
import { REDIRECT } from "../../../../../../constants/storage";
import { SVG_ICONS } from "../../../../../../components/__common/Svg/Svg";
import { Button } from "../../../../../../components/__common/_controls";

interface Props {
  setModalOpen: (value: boolean) => void;
  hasLocalSite: boolean;
}

const CountrySpecific: React.FC<Props> = ({ setModalOpen, hasLocalSite }) => {
  const { history } = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const historyHook = useHistory();
  const { t } = useTranslation();


    const { handleGAMyDataRedirectHub, handleGARequestHistoryRedirectHub } =
    useHub();
  const { toggleLoginModal } = useLoginOptionHooks();
  const withPreviousRequest =
    UtilsUser.isUserLoggedIn() && history.request_count > 0 && hasLocalSite;

  const handleMyDataRedirect = () => {
    if (UtilsUser.isUserLoggedIn()) {
      historyHook.push(
        withPreviousRequest ? ROUTES.MYDATA_HISTORY : ROUTES.MYDATA
      );
    } else {
      toggleLoginModal({
        isLoginModalOpen: true,
        loginModalRedirect: ROUTES.MYDATA,
      });
      sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
    }
  };
  return (
    <Container className="country-specific">
      <Container>
        <Typography
          variant="h1"
          className={
            withPreviousRequest
              ? "country-specific__title-scenario3"
              : "country-specific__title"
          }
        >
          {t("PRIVACY_HOME_SAMSUNG_PRIVACY POLICY")}
        </Typography>
      </Container>

      {UtilsUser.isUserLoggedIn() && hasLocalSite ? (
        <CountryCarousel setModalOpen={setModalOpen} />
      ) : (
        <PrivacyInfoCarousel />
      )}
      {!hasLocalSite ? 
        <RightsInfoCarousel
        hasLocalSite={hasLocalSite}
        setModalOpen={setModalOpen}
      />
        : <Container className="country-specific__your-rights">
        <Typography
          variant={withPreviousRequest ? "title1" : "title2"}
          className="country-specific__your-rights__title"
        >
          {withPreviousRequest
            ? t("PRIVACY_HUB_PREVREQUESTS_TITLE")
            : t("PRIVACY_HUB_YOURRIGHTS_TITLE")}
        </Typography>
        {withPreviousRequest ? (
          <>
            <Typography
              variant="body1"
              weight="w500"
              className="country-specific__your-rights__desc"
            >
              {t("PRIVACY_HUB_PREVREQUESTS_TEXT1")}
            </Typography>
            <Typography
              variant="body1"
              weight="w500"
              className="country-specific__your-rights__desc"
            >
              {t("PRIVACY_HUB_PREVREQUESTS_TEXT2")}
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              weight="w500"
              className="country-specific__your-rights__desc"
            >
              {t("PRIVACY_HUB_YOURRIGHTS_TEXT1")}
            </Typography>
            <Typography
              variant="body1"
              weight="w500"
              className="country-specific__your-rights__desc"
            >
              {t("PRIVACY_HUB_YOURRIGHTS_TEXT2")}
            </Typography>
          </>
        )}
        <Button
          title={
            withPreviousRequest
              ? t("PRIVACY_HUB_PREVREQUESTS_BTN_REQHISTIRY")
              : t("PRIVACY_HUB_YOURRIGHTS_BTN_MYDATA")
          }
          className="country-specific__your-rights__button"
          icon={SVG_ICONS.ARROW_RIGHT}
          iconPosition="right"
          onClick={() => {
            if (withPreviousRequest) {
              handleGARequestHistoryRedirectHub();
            } else {
              handleGAMyDataRedirectHub();
            }
            handleMyDataRedirect();
          }}
          type="content"
        />
      </Container>
      }
      
    </Container>
  );
};
export default CountrySpecific;
